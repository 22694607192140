import React from "react";
import RightSideImageWithDoctorInfo from "../sections/DoctorInfo/RightSideImageWithDoctorInfo";
import LeftSideImageWithDoctorInfo from "../sections/DoctorInfo/LeftSideImageWithDoctorInfo";
import Card from "../landingPage/CardsLayout/Card";
import { DRBHARGAVSADAWATE, DRPRIYANKASADAWATE } from "../../route/constant";
import BeforeAfterEffect from "../landingPage/BeforeAfterEffect/BeforeAfterEffect";
import GallerySection from "../landingPage/GallerySection/GallerySection";
import OfficeTiming from "../landingPage/OfficeSection/OfficeTiming";
// import TrustedBy from "../landingPage/TrsutedBy/TrustedBy";
import VideoSection from "../landingPage/VideoLandingPage";
import ImageWithLocation from "../landingPage/ImageWithLocation";
import { TrustedByData } from "../sections/TrustedBy/trustedByData";
import TrustedByNew from "../sections/TrustedBy/TrustedBy";
import WhyChooseUs from "./WhyChooseUs";
import WhatsappButton from "./WhatsappButton";

function FinalLandingPageSectionInOne() {
  return (
    <>
      <VideoSection />
      <RightSideImageWithDoctorInfo
        buttonText={"MEET THE TEAM"}
        name={DRBHARGAVSADAWATE}
        degree={"BDS, MDS (PEDODONTICS AND PREVENTIVE DENTISTRY)"}
      />
      <LeftSideImageWithDoctorInfo
        buttonText={"MEET THE TEAM"}
        name={DRPRIYANKASADAWATE}
        degree={"BDS, MDS, FICOI (PROSTHODONTICS AND IMPLANTOLOGY)"}
      />
      <Card />
      <GallerySection />
      <WhyChooseUs />
      <BeforeAfterEffect />
      {/* <OfficeTiming /> */}
      {/* <TrustedBy /> */}
      <TrustedByNew
        trustedByTitle="Trusted by"
        isScroll
        trustedByData={TrustedByData}
      />
      <ImageWithLocation />
      <WhatsappButton />
    </>
  );
}

export default FinalLandingPageSectionInOne;
