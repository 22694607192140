import image1 from "../../image/Associations/Rectangle 2.png";
import image2 from "../../image/Associations/Rectangle 3.png";
import image3 from "../../image/Associations/Rectangle 4.png";
import image4 from "../../image/Associations/Rectangle 5.png";
export const TrustedByData = [
  {
    trustedByLogo: image1,
  },
  {
    trustedByLogo: image2,
  },
  {
    trustedByLogo: image3,
  },
  {
    trustedByLogo: image4,
  },
];
