import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Adjust the path as needed
import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Blogs.scss";
import Spinner from "../common/Spinner";
import WhatsappButton from "../FinalComponents/WhatsappButton";

const convertDriveLink = (url) => {
  const match = url.match(/(?:file\/d\/|open\?id=|d\/)([\w-]+)/);
  return match
    ? `https://drive.google.com/thumbnail?id=${match[1]}&sz=w800-h600`
    : url;
};

const Blogs = () => {
  const [visibleBlogs, setVisibleBlogs] = useState(9);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "blogs"));
        const blogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogsData);
        setNoResults(blogsData.length === 0);
      } catch (error) {
        setError("Failed to fetch blogs");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const truncateHTML = (html, maxLength) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const text = tempElement.textContent || tempElement.innerText || "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const handleSeeMore = () => {
    setVisibleBlogs(visibleBlogs + 9);
  };

  if (loading) return <Spinner />;

  return (
    <>
      {noResults ? (
        <div className="no-results d-flex justify-content-center">
          <img
            src="https://foxcostumes.com/Images/no-result.png"
            alt="No Results"
          />
          <WhatsappButton />
        </div>
      ) : (
        <>
          <div className="blogs-container">
            {blogs.slice(0, visibleBlogs).map((blog) => (
              <div key={blog.id} className="blog-card">
                <img
                  src={
                    blog.image
                      ? convertDriveLink(blog.image)
                      : "https://th.bing.com/th/id/R.71dd52285ae55f507219b4d3c481847f?rik=744FPL0mfl%2fotA&riu=http%3a%2f%2fbadlapurdiary.com%2fwp-content%2fuploads%2f2023%2f03%2fthe-tooth-story-bd1.jpeg&ehk=iy7NE0vbJ9WNxubCrLMD9OoQJx1MwJkmO3n3xhbVxdQ%3d&risl=&pid=ImgRaw&r=0"
                  }
                  alt={blog.title}
                  className="blog-image object-contain"
                />
                <div className="blog-content">
                  <span className="blog-category">{blog.author}</span>
                  <h2 className="blog-title">{blog.title}</h2>
                  <div
                    className="blog-description"
                    dangerouslySetInnerHTML={{
                      __html: truncateHTML(blog.description, 100),
                    }}
                  ></div>
                  <div className="blog-footer">
                    <Link to={`/blog/${blog.id}`}>
                      <button className="bg-[#fb9220] text-white px-5 py-2 hover:bg-[#e08b22]">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            {visibleBlogs < blogs.length && (
              <button
                className="bg-[#fb9220] text-white px-5 py-2 rounded-full hover:bg-[#e08b22]"
                onClick={handleSeeMore}
              >
                See More
              </button>
            )}
          </div>
        </>
      )}
      <WhatsappButton />
    </>
  );
};

export default Blogs;
