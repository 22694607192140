import React from "react";
import ReactCompareImage from "react-compare-image";
import "./BeforeAfterEffect.scss";
import oneBefore from "../BeforeAfterEffect/1_before.JPG";
import oneAfter from "../BeforeAfterEffect/1_after.JPG";
import twoBefore from "../BeforeAfterEffect/2_before.JPG";
import twoAfter from "../BeforeAfterEffect/2_after.JPG";
import threeBefore from "../BeforeAfterEffect/3_before.JPG";
import threeAfter from "../BeforeAfterEffect/3_after.JPG";

const BeforeAfterEffect = () => {
  const BeforeAfterEffectData = [
    {
      Image1: oneBefore,
      Image2: oneAfter,
    },
    {
      Image1: twoBefore,
      Image2: twoAfter,
    },
    {
      Image1: threeBefore,
      Image2: threeAfter,
    },
  ];

  return (
    <>
      <div className="text_font_josefin text-center text-6xl  pt-5 mt-5 pb-3">
        Patient
        <span style={{ color: "#ff8c00" }}> Transformations</span>
      </div>
      <div className="d-flex flex-wrap justify-content-center p-3">
        {BeforeAfterEffectData.map(({ Image1, Image2 }, index) => {
          return (
            <div
              className="mainWrapper col-lg-4 col-md-6 col-12 mb-4"
              key={index}
            >
              <div className="m-20">
                <ReactCompareImage leftImage={Image1} rightImage={Image2} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BeforeAfterEffect;
