import React from "react";
import BookAppointmentBtn from "../common/BookAppointmentBtn";

export const cardData = [
  {
    title: "Cutting-Edge Dental Technology",
    text: "We utilize the latest advancements in dental technology to ensure precise, effective, and minimally invasive treatments.",
    icon: "🦷",
  },
  {
    title: "Highly Experienced Dental Specialists",
    text: "Our dental experts bring years of experience, skill, and a deep understanding of patient care to every procedure.",
    icon: "👩‍⚕️",
  },
  {
    title: "Comprehensive Care",
    text: "From your initial consultation to post-treatment follow-ups, we provide seamless, comprehensive care every step of the way.",
    icon: "🏥",
  },
  {
    title: "Proven History of Excellence",
    text: "We continuously strive to exceed expectations with personalized treatment plans tailored to your unique needs.",
    icon: "⭐",
  },
];

function CardLayout({ title, text, icon }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "20px",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
        margin: "10px",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-10px)";
        e.currentTarget.style.boxShadow = "0 12px 30px rgba(0, 0, 0, 0.15)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.1)";
      }}
    >
      <div
        style={{
          fontSize: "60px",
          marginBottom: "20px",
          backgroundColor: "#000",
          color: "#fff",
          borderRadius: "50%",
          padding: "30px",
        }}
      >
        {icon}
      </div>
      <h3
        style={{
          color: "#ff8c00",
          fontSize: "1.75rem",
          marginBottom: "15px",
          textAlign: "center",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          color: "#555",
          fontSize: "1rem",
          lineHeight: "1.8",
          textAlign: "center",
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default function WhyChooseUs() {
  return (
    <div
      style={{ padding: "40px", backgroundColor: "#fff", marginTop: "90px" }}
    >
      <h2
        style={{
          textAlign: "center",
          fontSize: "3rem",
          marginBottom: "30px",
          fontWeight: "bold",
        }}
      >
        Why Choose <span style={{ color: "#ff8c00" }}>The Tooth Story?</span>
      </h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "25px",
        }}
      >
        {cardData.map((val, id) => (
          <CardLayout
            key={id}
            title={val.title}
            text={val.text}
            icon={val.icon}
          />
        ))}
      </div>
      <p
        style={{
          textAlign: "center",
          marginTop: "30px",
          fontSize: "1.2rem",
          color: "#333",
        }}
      >
        Experience the difference with{" "}
        <span style={{ color: "#ff8c00", fontWeight: "bold" }}>
          The Tooth Story
        </span>{" "}
        – where every smile tells a story.
      </p>
      <div className="d-flex align-items-center justify-content-center">
        <BookAppointmentBtn className="bg-[#fb9220] text-white px-5 py-2 rounded-full hover:bg-[#e08b22]" />
      </div>
    </div>
  );
}
