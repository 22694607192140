import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.scss";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import reviewPerson from "./reviewGoogle.jpg";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const reviews = [
  {
    name: "Gajanan Dhaske",
    review:
      "Dr. Bhargav... Wonderful doctor. Caring to childrens equipped with new technology and usingquality material. Ambiance of the hospital is creative... Maintain same professionalism to long run.... Best Luck!!! 🎉 …",
  },
  {
    name: "Kanika Israni",
    review:
      "Friendly dentist, very spacious clinic, with thorough knowledge in the subject and immense care for the kids.First pedodontist in Badlapur.",
  },
  {
    name: "Atuk Bhutia",
    review:
      "I visited the clinic a little over a week ago to get my teeth scaled. Dr. Madhumanti was kind enough to accommodate me toward the end of her schedule and patiently completed the procedure. She also gave me sound advice on maintaining the teeth and gum health.",
  },
  {
    name: "Sharayu Joshi",
    review:
      "I got my root canal done at The Tooth Story and it was by far the best experience I have had with any dentist. Dr.Bhargav and Dr.Priyanka were always kind and considerate and suggested appropriate treatments for me.I’d definitely recommend tooth story!",
  },
  {
    name: "Prakash Patil",
    review:
      "Great experience, punctual treatment and Dr. Bhargav sir is very attentive and skillful. Also staff in the clinic are very cooperative. Thank you so very much for taking care of me and being so skilled at what you do.",
  },
];

const Testimonial = () => {
  return (
    <div
      className="testimonial pb-20 mb-5"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <div className="text_font_josefin text-center text-6xl  pt-5 mt-5 pb-3">
          <span style={{ color: "#ff8c00" }}>Google</span> Reviews
        </div>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          {reviews.map((item, index) => (
            <Card key={index} review={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ review }) => {
  const { name, review: reviewText } = review;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={reviewPerson}
        style={{
          width: 120,
          height: 120,
          border: "1px solid lightgray",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <div style={{ fontWeight: "bold", fontSize: "1.2em", marginTop: "10px" }}>
        {name}
      </div>
      <p>{reviewText}</p>
    </div>
  );
};

export default Testimonial;
