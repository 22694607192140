import React from 'react';
import Slider from 'react-slick';
import style from './TrustedBy.module.scss';

const TrustedByCard = ({ className, isScroll, trustedByData = [] }) => {
  const settings = {
    dots: false,
    infinite: isScroll,
    arrows: false,
    speed: 5000,
    autoplay: isScroll,
    autoplaySpeed: 0,
    cssEase: 'linear',
    variableWidth: true,
  };

  return (
    <>
      <Slider {...settings} className="mt-5">
        {trustedByData?.map((item, index) => (
          <img
            key={index}
            src={item.trustedByLogo}
            alt="Logo"
            className={`${style['responsive-imags']} ${style['trusted-by-logos']} ${className}`}
          />
        ))}
      </Slider>
    </>
  );
};

export default TrustedByCard;
