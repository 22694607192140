import React from "react";
import videoFile from "./Sequence-03.mp4";
import "./VideoLandingPage.scss";

const VideoBackground = () => {
  return (
    <div className="video-background">
      <video autoPlay loop muted playsInline>
        <source src={videoFile} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBackground;
