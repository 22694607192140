import teamPerson1 from "./men_dummy.jpg";
import teamPerson2 from "./women_dummy.jpg";

export const TeamMembers = [
  {
    name: "Dr. Prajot Yevale",
    proffessionalTitle: "Endodontist / Root Canal Specialist",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Nitesh Patkar",
    proffessionalTitle: "Oral and Maxillofacial Surgeon",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Anurag Singh",
    proffessionalTitle: "Orthodontist",
    teamImg: teamPerson1,
  },
  {
    name: "Dr. Mugdha Lawand",
    proffessionalTitle: "Periodontist",
    teamImg: teamPerson2,
  },
];
