import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import BookAppointmentBtn from "./BookAppointmentBtn";
import logo from "../image/svg/toothlogo.svg";
import "./navbar.scss";
import { NavLink } from "react-router-dom";
import clockLogo from "../image/HeaderIMG/clock.svg";
import callLogo from "../image/HeaderIMG/call.svg";
import locationLogo from "../image/HeaderIMG/location.svg";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="bg-[#ffe4c4] d-flex flex-column flex-md-row p-4 justify-content-between">
        <div className="mb-2 mb-md-0 d-flex align-items-center">
          <img src={clockLogo} alt="clockLogo" height={36} width={36} />
          <div>
            <strong>10:00AM-1:30PM & 5:00PM-8:30PM</strong>
            <span>{`(Friday Closed)`}</span>
          </div>
        </div>
        <div className="mb-2 mb-md-0 d-flex align-items-center">
          <img src={callLogo} alt="callLogo" height={36} width={36} />
          <div>
            <span>Call Us:</span>
            <strong>+91 8605334477</strong>
          </div>
        </div>
        <div className="mb-2 mb-md-0 d-flex align-items-center">
          <img src={locationLogo} alt="locationLogo" height={36} width={36} />
          <div>
            <span>Location:</span>
            <strong>Badlapur, Maharastra</strong>
          </div>
        </div>
      </div>
      <header className="bg-white">
        <nav className="p-3 flex justify-between items-center w-[92%] mx-auto">
          <div>
            <NavLink
              exact
              to="/"
              className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
            >
              <img className="w-16 cursor-pointer" src={logo} alt="logo" />
            </NavLink>
          </div>
          <div
            className={`z-1 mt-3 mt-sm-0 nav-links duration-500 md:static absolute bg-white md:min-h-fit min-h-[40vh] left-0 ${
              menuOpen ? "top-[9%]" : "top-[-100%]"
            } md:w-auto w-full flex items-center px-5`}
          >
            {isMobile && menuOpen && (
              <div onClick={toggleMenu}>
                <FaTimes />
              </div>
            )}
            <ul className="mb-0 flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8">
              <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                  exact
                  to="/"
                  className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
                >
                  Home
                </NavLink>
              </li>
              <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                  exact
                  to="/services"
                  className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
                >
                  Services
                </NavLink>
              </li>
              <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                  exact
                  to="/team-member"
                  className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
                >
                  Team
                </NavLink>
              </li>
              <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                  exact
                  to="/location"
                  className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
                >
                  Location
                </NavLink>
              </li>
              <li onClick={isMobile ? toggleMenu : undefined}>
                <NavLink
                  exact
                  to="/blog"
                  className="navbar-text text-black text-decoration-none header-link header-link-space-left header-link-space-right cursor-pointer"
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-6">
            <BookAppointmentBtn className="bg-[#fb9220] text-white px-5 py-2 rounded-full hover:bg-[#e08b22]" />
            {isMobile && (
              <div
                onClick={toggleMenu}
                className="text-3xl cursor-pointer md:hidden"
              >
                <FaBars />
              </div>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
