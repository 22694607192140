import React from "react";

export default function WhatsappButton() {
  const whatsappNumber = "918605334477";
  const message =
    "Hello The Tooth Story Team, I would like to inquire about a dental treatment at your facilities. Is it possible to schedule a Consultation?";

  const handleClick = () => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: "fixed",
        bottom: "4%",
        right: "4%",
        zIndex: "1000",
        cursor: "pointer",
        backgroundColor: "#25D366",
        borderRadius: "50%",
        padding: "clamp(10px, 2vw, 15px)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
        transition: "all 0.3s ease-in-out",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/icons8-whatsapp-48.png"
        alt="WhatsApp"
        style={{
          width: "clamp(36px, 4vw, 48px)",
          height: "clamp(36px, 4vw, 48px)",
          transition: "transform 0.2s ease-in-out",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </div>
  );
}
