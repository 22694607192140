import React from "react";
import TeamCard from "../sections/DoctorInfo/TeamCard";
import RightSideImageWithDoctorInfo from "../sections/DoctorInfo/RightSideImageWithDoctorInfo";
import { DRBHARGAVSADAWATE, DRPRIYANKASADAWATE } from "../../route/constant";
import LeftSideImageWithDoctorInfo from "../sections/DoctorInfo/LeftSideImageWithDoctorInfo";
import WhatsappButton from "./WhatsappButton";

const Team = () => {
  return (
    <>
      <RightSideImageWithDoctorInfo
        buttonText={"MEET THE TEAM"}
        name={DRBHARGAVSADAWATE}
        degree={"BDS, MDS (PEDODONTICS AND PREVENTIVE DENTISTRY)"}
        isButtonHide={true}
      />
      <LeftSideImageWithDoctorInfo
        buttonText={"MEET THE TEAM"}
        name={DRPRIYANKASADAWATE}
        degree={"BDS, MDS, FICOI (PROSTHODONTICS AND IMPLANTOLOGY)"}
        isButtonHide={true}
      />
      {/* <div className="text-center font-weight-bold p-5 m-5 bg-info"><h1> OUR TEAM </h1></div> */}
      <div className="pt-20 pb-20">
        <div className="text_font_josefin text-center text-6xl mb-5">
          OTHER TEAM MEMBERS
        </div>
        <TeamCard />
      </div>
      <WhatsappButton />
    </>
  );
};

export default Team;
