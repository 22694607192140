import React from "react";
import "../navbar.scss";
import { NavLink } from "react-router-dom";
import logo from "../../image/svg/toothlogo.svg";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="bg-white dark:bg-gray-900">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 mt-sm-3">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <img
                src={logo}
                className="ml-n3"
                alt="Logo"
                width={100}
                height={100}
              />
              <div className="mb-5 mb-sm-0">
                <h2 className="mb-3 text-sm font-bold text-black uppercase footer-title-fw">
                  Contact Us
                </h2>
                <a
                  className="cursor-pointer"
                  target="_blank"
                  href="https://www.google.com/maps/place/The+Tooth+Story-+Dental+Clinic+for+Kids+%26+Adults+and+Implant+Centre/@19.167242,73.235571,15z/data=!4m6!3m5!1s0x3be7ed2ad673c80f:0x9ddcd5ad69c4d057!8m2!3d19.167242!4d73.2355705!16s%2Fg%2F11b6p6g0rw?hl=en&entry=ttu"
                >
                  <h6>103, Manohar Mhaskar Market,</h6>
                  <h6>Oppo. Navratna Hotel, Station Rd,</h6>
                  <h6>Badlapur, Maharashtra</h6>
                </a>
                {/* TODO: email functionality should be added */}
                <h6>Email: thetoothstory@yahoo.com</h6>
                {/* TODO: copy functionality should be added */}
                <h6>Phone: +91 8605334477</h6>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2
                  className="mb-6 text-sm font-bold text-black uppercase footer-title-fw"
                  style={{ color: "#ff8c00" }}
                >
                  Quick Links
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium pl-0">
                  <li className="mb-2">
                    <NavLink
                      exact
                      to="/"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink
                      exact
                      to="/services"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Services{" "}
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink
                      exact
                      to="/team-member"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Our Team{" "}
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink
                      exact
                      to="/location"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Location{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/blog"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Blogs{" "}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div>
                <h2
                  className="mb-6 text-sm font-bold text-black uppercase footer-title-fw"
                  style={{ color: "#ff8c00" }}
                >
                  Follow us
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium pl-0">
                  <li className="mb-2">
                    <a
                      href="https://www.facebook.com/thetoothstoryitendshere/"
                      className="text-decoration-none text-black navbar-text"
                    >
                      facebook
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="https://www.instagram.com/thetoothstorydc?igsh=bHQxbHNoOXJpbjk0"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div>
                <h2 className="mb-6 text-sm font-bold text-black uppercase footer-title-fw">
                  About Us
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium pl-0">
                  <li className="mb-2">
                    <a
                      href="#"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Why choose us ?
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="#"
                      className="text-decoration-none text-black navbar-text"
                    >
                      Our vision
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="text-decoration-none text-black navbar-text"
                    >
                      What makes us best ?
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              &copy; {currentYear} The Tooth Story. All rights reserved.
            </span>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.facebook.com/thetoothstoryitendshere/"
                className="text-gray-500 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Facebook Page</span>
              </a>

              <a
                href="https://www.instagram.com/thetoothstorydc?igsh=bHQxbHNoOXJpbjk0"
                className="text-gray-500 hover:text-gray-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                >
                  <path d="M349.33 69.33H162.67C107.47 69.33 69.33 107.47 69.33 162.67v186.66c0 55.2 38.14 93.34 93.34 93.34h186.66c55.2 0 93.34-38.14 93.34-93.34V162.67c0-55.2-38.14-93.34-93.34-93.34zm62.67 280c0 34.59-28.08 62.67-62.67 62.67H162.67c-34.59 0-62.67-28.08-62.67-62.67V162.67c0-34.59 28.08-62.67 62.67-62.67h186.66c34.59 0 62.67 28.08 62.67 62.67v186.66z" />
                  <path d="M256 144c-61.76 0-112 50.24-112 112s50.24 112 112 112 112-50.24 112-112-50.24-112-112-112zm0 176c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z" />
                  <circle cx="393.6" cy="118.4" r="17.07" />
                </svg>
                <span className="sr-only">Instagram Page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
